import { Component, Input } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { AccessibilityIssueRepo } from '@monsido/modules/endpoints/api/accessibility-issue.repo';
import { IssueInterface } from '../../interfaces/issue-interface';

@Component({
    selector: 'mon-help-center-btn',
    templateUrl: './mon-help-center-btn.html',
})
export class MonAccHelpCenterBtnComponent {

    @Input() check?: IssueInterface;
    loading: boolean = false;

    constructor (private accessibilityIssueRepo: AccessibilityIssueRepo,
        private eventService: MonEventService) {}

    openHelpCenter (): void {
        this.loading = true;
        if (this.check) {
            this.accessibilityIssueRepo.getHelp(this.check.id).then(
                (data) => {
                    if (this.check) {
                        if (data.article_url && typeof data.article_url === 'string') {
                            window.open(data.article_url, '_blank');
                        } else if (data.suggestion === '') {
                            // New center
                            this.openNewCenter(this.check, data);
                        } else {
                            // Old center
                            this.openOldCenter(this.check, data);
                        }
                    }
                }, ()=>{})
                .finally(()=> {
                    this.loading = false;
                });
        }
    }

    private openOldCenter (check: IssueInterface, data: Record <string, string | string[] | number | boolean>): void {
        const params = {
            body: 'accessibilityDialogsHelpCenter',
            data: { check: check, data: data },
            size: 'lg',
        };

        const options = {
            params,
        };
        this.eventService.run(MON_EVENTS.LOAD_DIALOG, options);
    }

    private openNewCenter (check: IssueInterface, data: Record <string, string | string[] | number | boolean>): void {
        const params = {
            body: 'accessibilityDialogsNewHelpCenter',
            data: { check: check, data: data },
            size: 'lg',
        };

        const options = {
            params,
        };

        this.eventService.run(MON_EVENTS.LOAD_DIALOG, options);
    }

}
